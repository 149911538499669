import React, { useEffect, useRef } from "react";
import { AccordionCollapse } from "./Accordion";
export const ChatMessage = ({ message }) => {
  const messagesEndRef = useRef(null);

  const isUser = () => {
    return message.user === "user";
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
      scrollIntoViewOptions: { block: "end", inline: "nearest" },
    });
  };

  useEffect(scrollToBottom, [message.message]);

  const parseRes = (message = {}) => {
    let items = [];
    let introMessage = "";
    let trailingMessage = "";

    const splitText = message.split("--");

    for (let i = 0; i < splitText.length; i++) {
      if (i === 0) {
        introMessage = splitText[i];
      } else if (i === splitText.length - 1) {
        const match = splitText[i].match(/\n/);
        if (match) {
          items.push(
            splitText[i].substring(0, splitText[i].indexOf(match[0])).trim()
          );
          trailingMessage = splitText[i]
            .substring(splitText[i].indexOf(match[0]))
            .trim();
        }
      } else {
        items.push(splitText[i].trim());
      }
    }
    return [introMessage, items, trailingMessage];
  };
  return (
    <div className="chat-message-container">
      <div className="chat-message">
        <div className={isUser() ? "user-icon" : "ai-icon"}></div>
        <div className="message">{parseRes(message.message)[0]}</div>
      </div>
      {parseRes(message.message)[1].map((item) => {
        return <AccordionCollapse item={item} />;
      })}
      {parseRes(message.message)[2] && (
        <div className="chat-message-container">
          <div className="chat-message chat-message-end">
            <div className={"ai-icon"}></div>
            <div className="message">{parseRes(message.message)[2]}</div>
          </div>
        </div>
      )}

      <div ref={messagesEndRef} />
    </div>
  );
};
