import React, { useState } from "react";
import { ChatMessage } from "./components/ChatMessage";
import { Intro } from "./components/Intro";
import { Loader } from "./components/Loader";
import "./App.css";

function App() {
  const [question, setQuestion] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleQuestion = async (e) => {
    e.preventDefault();
    if (question.length <= 4) return null;

    let newChatLog = [...chatLog, { user: "user", message: question }];

    setQuestion(() => {
      return "";
    });

    setChatLog([...newChatLog]);

    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        message: newChatLog.map((message) => message.message).join(""),
      }),
    };
    setLoader(true);
    const response = await fetch("https://server.diypro.ai/", reqOptions);

    const data = await response.json();
    setLoader(false);
    await setChatLog([...newChatLog, { user: "diy", message: data?.message }]);
  };

  return (
    <div className="App">
      <aside className="side-menu">hi</aside>
      <section className="chat-container">
        <Intro />
        <div className="chat-log">
          {chatLog.map((message, index) => {
            return <ChatMessage key={index} message={message} />;
          })}
          {loader && <Loader />}
        </div>
      </section>
      <section className="input-container">
        <div className="client-input-holder">
          <form className="input-form" onSubmit={handleQuestion}>
            <div className="client-input-textarea">
              <input
                row="1"
                className="client-input"
                autoFocus="autofocus"
                type="text"
                placeholder="Describe what you want to make"
                value={question}
                onChange={handleChange}
              />
              <span className="arrow flow right "></span>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default App;
