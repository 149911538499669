import React from "react";

export const Intro = () => {
  return (
    <div>
      <h2>DIY AI</h2>
      <p className="intro">
        Welcome to Do-It-Yourself AI. Tell us what you'd like to work on, and
        we'll provide{" "}
        <span className="eye-catcher"> everything you'll need</span> to get it
        done 🔨😄.
      </p>
      <div className="chat-message-container">
        <div className="chat-message">
          <div className="ai-icon"></div>
          <div className="message">
            <p>
              Hey there! Here's a few things you can search to give you an
              idea...
            </p>
            <ul className="dashed">
              <li> Build a dresser </li>
              <li> Repaint my room </li>
              <li> Remodel my kitchen cabinets </li>
              <li> Plant a garden</li>
              <li> Fix a hole in my jeans</li>
            </ul>
            <p>Just to name a few!</p>
          </div>
        </div>
      </div>
    </div>
  );
};
