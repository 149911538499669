import React from "react";

export const Loader = () => {
  return (
    <div className="chat-message-container">
      <div className="chat-message">
        <div className={"ai-icon"}></div>
        <div className="message">
          <div className="chat-bubble">
            <div className="typing">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
