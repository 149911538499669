import Accordion from "react-bootstrap/Accordion";

export const AccordionCollapse = ({ item }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{item}</Accordion.Header>
        <Accordion.Body>
          <div className="company-tile">
            <div className="TargetCo">
              <div className="TargetIcon icon"></div>
              <div className="content">
                <p className="description">{`Checkout the ${item}'s available at Target`}</p>
                <a
                  href={`https://www.target.com/s?searchTerm=${item}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>Target</button>
                </a>
              </div>
            </div>
            <div className="TargetCo">
              <div className="AmazonIcon icon"></div>
              <div className="content">
                <p className="description">{`Checkout the ${item}'s available at Amazon`}</p>
                <a
                  href={`https://www.amazon.com/s?k=${item}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>Amazon</button>
                </a>
              </div>
            </div>
            <div className="TargetCo">
              <div className="WalmartIcon icon"></div>
              <div className="content">
                <p className="description">{`Checkout the ${item}'s available at Walmart`}</p>
                <a
                  href={`https://www.walmart.com/search?q=${item}&typeahead=${item}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>Walmart</button>
                </a>
              </div>
            </div>
            <div className="TargetCo">
              <div className="CostcoIcon icon"></div>
              <div className="content">
                <p className="description">{`Checkout the ${item}'s available at Costco`}</p>
                <a
                  href={`https://www.costco.com/CatalogSearch?dept=All&keyword=${item}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>Costco</button>
                </a>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
